
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as formulairezPgUmabdgGMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/administration/acteurs/[k]/formulaire.vue?macro=true";
import { default as indexA71prjwnaNMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/administration/acteurs/[k]/index.vue?macro=true";
import { default as _91k_93GOvI0uVLB6Meta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/administration/acteurs/[k].vue?macro=true";
import { default as acteursak5PjR7iM3Meta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/administration/acteurs.vue?macro=true";
import { default as configurationMrzZSjfYzUMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/administration/configuration.vue?macro=true";
import { default as formulairekiqGUtSuaOMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/administration/territoires/[k]/formulaire.vue?macro=true";
import { default as indexzUDbH6vwSJMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/administration/territoires/[k]/index.vue?macro=true";
import { default as _91k_93gf5sDoFEGjMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/administration/territoires/[k].vue?macro=true";
import { default as territoiresrwSkEhfrRLMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/administration/territoires.vue?macro=true";
import { default as utilisateurs_45permissions4Vod334h2lMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/administration/utilisateurs-permissions.vue?macro=true";
import { default as formulaireMk12jaTZ5oMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k]/formulaire.vue?macro=true";
import { default as indexM7OuLwA8TYMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k]/index.vue?macro=true";
import { default as _91k_93NGoNS4MRQCMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k].vue?macro=true";
import { default as utilisateursNC5y4ogMTnMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/administration/utilisateurs.vue?macro=true";
import { default as indexHegY8JaGgVMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/connexion/index.vue?macro=true";
import { default as _91token_9321bHnCO9YlMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie/[token].vue?macro=true";
import { default as index0EtLap9Ov7Meta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie/index.vue?macro=true";
import { default as mot_45de_45passe_45oublieaRFkRxEejaMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie.vue?macro=true";
import { default as contactV5h59Ym0bxMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/contact.vue?macro=true";
import { default as donnees_45personnellesOzHzpetHKxMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/donnees-personnelles.vue?macro=true";
import { default as _91k_93PyARnZFlM6Meta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/download/[k].vue?macro=true";
import { default as erreurVsrbQywvVsMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/erreur.vue?macro=true";
import { default as formulaire1kvt9LbbrTMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k]/formulaire.vue?macro=true";
import { default as indexxim1HKQufOMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k]/index.vue?macro=true";
import { default as _91k_93ZVpuAIBzdCMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k].vue?macro=true";
import { default as controles_45simultanesCuw0vsDgbbMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/controles-simultanes.vue?macro=true";
import { default as formulaireJeaOyUe0ixMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k]/formulaire.vue?macro=true";
import { default as indexPRagusundeMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k]/index.vue?macro=true";
import { default as _91k_93cvPIomKZKvMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k].vue?macro=true";
import { default as envoi_45de_45mailebqDbmFm9jMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail.vue?macro=true";
import { default as historiquenxBkDk8FuoMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/historique.vue?macro=true";
import { default as formulaire4FAlFaWO0xMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/import/[k]/formulaire.vue?macro=true";
import { default as indexFjaNj3YobeMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/import/[k]/index.vue?macro=true";
import { default as _91k_93QPiGBwxOKVMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/import/[k].vue?macro=true";
import { default as import28LNn2a1LNMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/import.vue?macro=true";
import { default as formulairegjWYs7sOvCMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k]/formulaire.vue?macro=true";
import { default as indexlxnGJEkimLMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k]/index.vue?macro=true";
import { default as _91k_93Kyi7HzKP6mMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k].vue?macro=true";
import { default as indisponibilites_45programmees7XJ7Max176Meta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees.vue?macro=true";
import { default as formulaireBSG0uNc1hmMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/pei/[k]/formulaire.vue?macro=true";
import { default as indexGcY6721uZPMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/pei/[k]/index.vue?macro=true";
import { default as _91k_93NZs8ikfuz4Meta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/pei/[k].vue?macro=true";
import { default as peiCLWiKmk61GMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/pei.vue?macro=true";
import { default as formulaire7jtJA8PWzcMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/tournees/[k]/formulaire.vue?macro=true";
import { default as indexvd2qXjQY54Meta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/tournees/[k]/index.vue?macro=true";
import { default as _91k_931F0U6apS8FMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/tournees/[k].vue?macro=true";
import { default as tourneesZH4BBORTsyMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/tournees.vue?macro=true";
import { default as indexiQ5Kf17za9Meta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/index.vue?macro=true";
import { default as index16P4OrORpOMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/profil/index.vue?macro=true";
import { default as profiljKD11GV7dtMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/profil.vue?macro=true";
import { default as ressourcestfwXgpUFAMMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/ressources.vue?macro=true";
import { default as tableau_45de_45bordeYloO5MNFeMeta } from "/builds/9543222784/hydraclic-v2/nuxt/pages/tableau-de-bord.vue?macro=true";
export default [
  {
    name: "administration-acteurs",
    path: "/administration/acteurs",
    meta: acteursak5PjR7iM3Meta || {},
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/administration/acteurs.vue"),
    children: [
  {
    name: _91k_93GOvI0uVLB6Meta?.name,
    path: ":k()",
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/administration/acteurs/[k].vue"),
    children: [
  {
    name: "administration-acteurs-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/administration/acteurs/[k]/formulaire.vue")
  },
  {
    name: "administration-acteurs-k",
    path: "",
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/administration/acteurs/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "administration-configuration",
    path: "/administration/configuration",
    meta: configurationMrzZSjfYzUMeta || {},
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/administration/configuration.vue")
  },
  {
    name: "administration-territoires",
    path: "/administration/territoires",
    meta: territoiresrwSkEhfrRLMeta || {},
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/administration/territoires.vue"),
    children: [
  {
    name: _91k_93gf5sDoFEGjMeta?.name,
    path: ":k()",
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/administration/territoires/[k].vue"),
    children: [
  {
    name: "administration-territoires-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/administration/territoires/[k]/formulaire.vue")
  },
  {
    name: "administration-territoires-k",
    path: "",
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/administration/territoires/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "administration-utilisateurs-permissions",
    path: "/administration/utilisateurs-permissions",
    meta: utilisateurs_45permissions4Vod334h2lMeta || {},
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/administration/utilisateurs-permissions.vue")
  },
  {
    name: "administration-utilisateurs",
    path: "/administration/utilisateurs",
    meta: utilisateursNC5y4ogMTnMeta || {},
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/administration/utilisateurs.vue"),
    children: [
  {
    name: _91k_93NGoNS4MRQCMeta?.name,
    path: ":k()",
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k].vue"),
    children: [
  {
    name: "administration-utilisateurs-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k]/formulaire.vue")
  },
  {
    name: "administration-utilisateurs-k",
    path: "",
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "connexion",
    path: "/connexion",
    meta: indexHegY8JaGgVMeta || {},
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/connexion/index.vue")
  },
  {
    name: mot_45de_45passe_45oublieaRFkRxEejaMeta?.name,
    path: "/connexion/mot-de-passe-oublie",
    meta: mot_45de_45passe_45oublieaRFkRxEejaMeta || {},
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie.vue"),
    children: [
  {
    name: "connexion-mot-de-passe-oublie-token",
    path: ":token()",
    meta: _91token_9321bHnCO9YlMeta || {},
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie/[token].vue")
  },
  {
    name: "connexion-mot-de-passe-oublie",
    path: "",
    meta: index0EtLap9Ov7Meta || {},
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie/index.vue")
  }
]
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/contact.vue")
  },
  {
    name: "donnees-personnelles",
    path: "/donnees-personnelles",
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/donnees-personnelles.vue")
  },
  {
    name: "download-k",
    path: "/download/:k()",
    meta: _91k_93PyARnZFlM6Meta || {},
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/download/[k].vue")
  },
  {
    name: "erreur",
    path: "/erreur",
    meta: erreurVsrbQywvVsMeta || {},
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/erreur.vue")
  },
  {
    name: "gestion-controles-simultanes",
    path: "/gestion/controles-simultanes",
    meta: controles_45simultanesCuw0vsDgbbMeta || {},
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/controles-simultanes.vue"),
    children: [
  {
    name: _91k_93ZVpuAIBzdCMeta?.name,
    path: ":k()",
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k].vue"),
    children: [
  {
    name: "gestion-controles-simultanes-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k]/formulaire.vue")
  },
  {
    name: "gestion-controles-simultanes-k",
    path: "",
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-envoi-de-mail",
    path: "/gestion/envoi-de-mail",
    meta: envoi_45de_45mailebqDbmFm9jMeta || {},
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail.vue"),
    children: [
  {
    name: _91k_93cvPIomKZKvMeta?.name,
    path: ":k()",
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k].vue"),
    children: [
  {
    name: "gestion-envoi-de-mail-k-formulaire",
    path: "formulaire",
    meta: formulaireJeaOyUe0ixMeta || {},
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k]/formulaire.vue")
  },
  {
    name: "gestion-envoi-de-mail-k",
    path: "",
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-historique",
    path: "/gestion/historique",
    meta: historiquenxBkDk8FuoMeta || {},
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/historique.vue")
  },
  {
    name: "gestion-import",
    path: "/gestion/import",
    meta: import28LNn2a1LNMeta || {},
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/import.vue"),
    children: [
  {
    name: _91k_93QPiGBwxOKVMeta?.name,
    path: ":k()",
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/import/[k].vue"),
    children: [
  {
    name: "gestion-import-k-formulaire",
    path: "formulaire",
    meta: formulaire4FAlFaWO0xMeta || {},
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/import/[k]/formulaire.vue")
  },
  {
    name: "gestion-import-k",
    path: "",
    meta: indexFjaNj3YobeMeta || {},
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/import/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-indisponibilites-programmees",
    path: "/gestion/indisponibilites-programmees",
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees.vue"),
    children: [
  {
    name: _91k_93Kyi7HzKP6mMeta?.name,
    path: ":k()",
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k].vue"),
    children: [
  {
    name: "gestion-indisponibilites-programmees-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k]/formulaire.vue")
  },
  {
    name: "gestion-indisponibilites-programmees-k",
    path: "",
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-pei",
    path: "/gestion/pei",
    meta: peiCLWiKmk61GMeta || {},
    alias: ["/gestion/carte"],
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/pei.vue"),
    children: [
  {
    name: _91k_93NZs8ikfuz4Meta?.name,
    path: ":k()",
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/pei/[k].vue"),
    children: [
  {
    name: "gestion-pei-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/pei/[k]/formulaire.vue")
  },
  {
    name: "gestion-pei-k",
    path: "",
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/pei/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-tournees",
    path: "/gestion/tournees",
    meta: tourneesZH4BBORTsyMeta || {},
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/tournees.vue"),
    children: [
  {
    name: _91k_931F0U6apS8FMeta?.name,
    path: ":k()",
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/tournees/[k].vue"),
    children: [
  {
    name: "gestion-tournees-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/tournees/[k]/formulaire.vue")
  },
  {
    name: "gestion-tournees-k",
    path: "",
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/gestion/tournees/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/index.vue")
  },
  {
    name: profiljKD11GV7dtMeta?.name,
    path: "/profil",
    meta: profiljKD11GV7dtMeta || {},
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/profil.vue"),
    children: [
  {
    name: "profil",
    path: "",
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/profil/index.vue")
  }
]
  },
  {
    name: "ressources",
    path: "/ressources",
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/ressources.vue")
  },
  {
    name: "tableau-de-bord",
    path: "/tableau-de-bord",
    meta: tableau_45de_45bordeYloO5MNFeMeta || {},
    component: () => import("/builds/9543222784/hydraclic-v2/nuxt/pages/tableau-de-bord.vue")
  }
]