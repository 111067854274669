<template>
    <div>
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
    </div>
</template>

<script setup>
const { $i18n} = useNuxtApp();
import {useUserStore} from '~/store'
import {useConfigStore} from "~/store";
import { useRouter } from 'vue-router'
// const {route} = useRoute().params //  necessaire (damien)?
// const {locale} = useI18n() //  necessaire (damien)?

await useUserStore().fetchUser();
// const currentUser = useUserStore().currentUser
// if (!currentUser.k) {
//     const router = useRouter()
//     router.replace('/connexion')
// }

await useConfigStore().fetchConfig();

if (useConfigStore().maintenance) {
    const router = useRouter()
    router.replace('/')
}

const currentCodeDep = useConfigStore().currentConfig.code_departement
console.log('i18n', $i18n, $i18n.locale.value, currentCodeDep)
if (currentCodeDep) {
    $i18n.setLocale(currentCodeDep)
    console.log('i18n', $i18n, $i18n.locale.value)
}

// const configStore = useConfigStore()
// const {fetchConfig} = configStore;
// await fetchConfig();

</script>
