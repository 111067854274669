<template>
    <div class="mt-5">
        <div class="mb-6">
            <ListActionsButtonsExport
                :query="$refs?.filters?.query"
                :request-name
                :translation-name
                :exportable
            />
        </div>
        <ListFilters
            v-if="filters"
            ref="filters"
            :permission-name="permissionName"
            :filters="filters"
            :filters-overload="filtersOverload"
            :tabs-enabled="['basic']"
            :translation-name="translationName"
            @load-data="loadData()"
            @reset-pagination="$refs.pagination.resetPagination()"
        />
        <v-card class="pa-5">
            <ListTable
                ref="table"
                :permission-name="permissionName"
                :translation-name="translationName"
                :sortable="sortable"
                :default-url="defaultUrl"
                :loading="loading"
                :data="data"
                :columns="columns"
                :init-sort="initSort"
                :click-columns="clickColumns"
                no-action

                @reset-pagination="$refs.pagination.resetPagination()"
                @load-data="loadData()"
                @delete-data="deleteData($event)"
                @click-column="clickColumn($event)"
            />
            <div>
                <ListPagination
                    ref="pagination"
                    :total="total"
                    :loading="loading"
                    :last-page="lastPage"
                    @load-data="loadData"
                />
            </div>
        </v-card>
        <NuxtPage
            :request-name="requestName"
            :default-url="defaultUrl"
            :translation-name="translationName"
            :permission-name="permissionName"
            @load-data="loadData()"
        />
    </div>
</template>

<script lang="ts">
import moment from 'moment'
import tablePageInit from '~/packages/datakode/nuxt-datakode-utils/utils/tablePageInit.vue'

export default {
    name : "Historiques",
    extends : tablePageInit,
    setup() {
        useHead({
            title : "Hydraclic | Historique",
            meta : [
                { name : 'description', content : "Journal des modifications des PEI." }
            ]
        })
        definePageMeta({
            middleware: ['auth']
        });
    },
    data () {
        return {
            moment,
            permissionName : 'changeLog',
            requestName : 'change_logs',
            defaultUrl : '/gestion/historique',
            translationName : 'gestion.historique',
            filtersOverload : ['pei_revision', 'numero_long', 'event_type', 'loggable_type', 'app', 'credentials.created_at', 'credentials.created_by', 'commune_code'],
            clickColumns : ['pei.numero_long'],
            columns : [
                { dataKey : 'pei_revision' },
                { dataKey : 'pei.numero_long', template : [this.redirectToPei] },
                { dataKey : 'type',transform : [this.toTranslate], template : [this.isChipsApp] },
                { dataKey : 'after_values', template : [this.generateDetailRow] },
                { dataKey : 'credentials.created_at', transform : [this.isDateTime] },
                { dataKey : 'credentials.created_by.name' },
                { dataKey : 'commune.nom' },
            ],
            initSort : {
                // key : "commune",
                // order : "asc"
            },
        }
    },
    methods : {
        // checkIsDate (date) {
        //     // let newDate = new Date(date)
        //     // return newDate !== "Invalid Date" && typeof date !== 'number' && !isNaN(newDate.getTime());
        //     let value = moment(date, 'YYYY-MM-DD', true)
        //     return value.isValid()
        // },
        checkValuesNotEmpty(beforeValue: string | any[], currentValue: string | any[]) {
            if (
                (!beforeValue || (Array.isArray(beforeValue) && !beforeValue.length) || beforeValue === '<p></p>')
                && (!currentValue || (Array.isArray(currentValue) && !currentValue.length) || currentValue === '<p></p>')
            ) {
                return false
            }
            return true
        },
        setupValue (value) {
            if (value === '' || value === '<p></p>') {
                return "<i>texte vide</i>"
            }
            if (!value) {
                return "<i>aucune valeur</i>"
            }
            if (typeof value === "boolean") {
                return value ? "Oui" : "Non"
            }
            // if (this.checkIsDate(value)) {
            //     return value.includes('T') ? this.$formatDateTime(value) : this.$formatDate(value)
            // }
            if (Array.isArray(value)) {
                return !value.length ? "<i>aucune valeur</i>" : value.join(', ')
            }
            if (typeof value === "object") {
                return JSON.stringify(value)
            }
            return value
        },
        isChipsApp (key, value, template, item) {
            let html = ""
            html += value ? `<v-chip class="my-1" size="small" color="${this.$getColor(`${this.translationName}.event_type.${value}`)}">${template}</v-chip>` : template
            html += `<br/><v-chip class="mb-1" size="x-small">${this.$translate(`${this.translationName}.object_type.${item.object_type}`)}</v-chip>`
            html += item.app && `<br/><v-chip size="x-small" class="mb-1" color="${this.$getColor(`${this.translationName}.app.${item.app}`)}">${this.$translate(`${this.translationName}.app.${item.app}`)}</v-chip>`
            return html
        },
        clickColumn (event) {
            if (event.column === 'pei.numero_long' && event.item.type !== 'deleted') {
                this.$router.push(`/gestion/pei/${event.item.pei.numero_long}`)
            }
        },
        generateDetailRow(key, value, template, item) {
            let html = ""
            if (value && typeof value === 'object') { //  && !(item.type == "created" && item.object_type === "Pei")
                const keys = Object.keys(value)
                const path = item.object_type === 'TechnicalControl' ? 'pei.last_technical_control' : item.object_type === 'OperationalRecognition' ? 'pei.last_operational_recognition' : 'pei'
                keys.forEach((key) => {
                    if (item.before_values && this.checkValuesNotEmpty(item.before_values[key], value[key])) {
                        html += `${this.$translate(`gestion.${path}.${key}`)} : `
                        if (item.type !== 'created') {
                            html += `de <span class="bg-deep-orange-lighten-4 pa-1 font-weight-bold">${this.setupValue(item.before_values[key])}</span> à `
                        }
                        html += `<span class="font-weight-bold ${item.event_type !== 'created' && 'bg-green-lighten-4 pa-1'}">${this.setupValue(value[key])}</span><br/>`
                    }
                    if (item.after_values?.version1) {
                        html += value.version1
                    }
                })
            }
            return html
        },
        redirectToPei (key, value, template/*, item*/) {
            return `<span class="text-info" style="cursor:pointer">${template}</span>`
        }
    },
};
</script>
