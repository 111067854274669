<template>
    <div id="utilisateurs">
        <ListActionsButtons
            :query="$refs?.filters?.query"
            :request-name="requestName"
            :translation-name="translationName"
            :exportable="exportable"
            :default-url="defaultUrl"
            :permission-name="permissionName"
        />


        <ListFilters
            v-if="filters"
            ref="filters"
            :permission-name="permissionName"
            :filters="filters"
            :tabs-enabled="['basic']"
            :translation-name="translationName"
            :filters-overload="filtersOverload"
            @load-data="loadData()"
            @reset-pagination="$refs?.pagination?.resetPagination()"
        />
        <v-card class="pa-5">
            <a href="/administration/utilisateurs-permissions" target="_blank">
                <v-btn
                    color="secondary" variant="tonal"
                    class="mb-4"
                >
                    Voir le tableau des permissions
                </v-btn>
            </a>

            <!-- <ListUtilisateursPermissionsTable /> -->
            <template v-if="configStore.currentConfig.ldap_enabled ">
                <ListDeleteConfirmation
                        v-if="$permissionCheck(`ldap_import`)"
                        action-name="mass-import-ldap"
                        :with-textarea="false"
                        :item="{}"
                        :translation-name="translationName"
                        :button="`<v-btn color='danger' variant='tonal' class='mb-4 ml-4'>Import de masse LDAP</v-btn>`"
                        @delete-data="importLdap"
                    />
            </template>

            <ListTable
                ref="table"
                :value-as-view-link="valueAsViewLink"
                :permission-name="permissionName"
                :translation-name="translationName"
                :sortable="sortable"
                :default-url="defaultUrl"
                :loading="loading"
                :data="data"
                :columns="columns"
                :init-sort="initSort"
                @reset-pagination="$refs?.pagination?.resetPagination()"
                @load-data="loadData()"
                @delete-data="deleteData($event)"
                @restore-data="restoreData($event)"
            />
            <div>
                <ListPagination
                    ref="pagination"
                    :total="total"
                    :loading="loading"
                    :last-page="lastPage"
                    @load-data="loadData"
                />
            </div>
        </v-card>
        <NuxtPage
            :request-name="requestName"
            :default-url="defaultUrl"
            :translation-name="translationName"
            :permission-name="permissionName"
            @load-data="loadData()"
            @delete-data="deleteData($event)"
        />
    </div>
</template>

<script lang="ts">
import tablePageInit from '~/packages/datakode/nuxt-datakode-utils/utils/tablePageInit.vue'
import { useConfigStore } from '~/store'

export default {
    name : "ListeUtilisateurs",
    extends : tablePageInit,
    setup() {
        useHead({
            title : "Hydraclic | Utilisateurs",
            meta : [
                { name : 'description', content : "Gestion des utilisateurs" }
            ]
        })
        definePageMeta({
            middleware: ['auth']
        });
        const configStore = useConfigStore()
        return {
            configStore,
        }

    },
    data () {
        return {
            permissionName : 'user',
            requestName : 'users',
            defaultUrl : '/administration/utilisateurs',
            translationName : 'administration.utilisateurs',
            valueAsViewLink : ['username'],
            columns : [
                { dataKey : 'username', template : [this.showDisabledUser] },
                { dataKey : 'name' },
                { dataKey : 'email' },
                { dataKey : 'role', transform : [this.toTranslate], template : [this.isChips] },
                { dataKey : 'territory.t', template : [this.isChips] },
                { dataKey : 'last_connexion', template : [this.isDateTime] },
                { dataKey : 'credentials.created_at', template : [this.isDateTime] },
            ],
            initSort : {
                key : "name",
                order : "asc"
            },
            filtersOverload : ['username', 'name', 'email', 'role', 'territory_id', 'has_trashed', 'last_connexion'],
        }
    },
    methods : {
        showDisabledUser (key, value, template, item) {
            let html = value
            if (item.deleted) {
                html += ` <br/>
                        <v-chip color="error" size="small">
                            Désactivé
                        </v-chip>
                    `
            }
            return html
        },
        importLdap() {
          this.$api.setRequest({
              url: 'ldap/import',
              method: 'GET',
          }).then(res => {
            this.$showToaster({ title : "Import de masse LDAP", text : `L'import LDAP a été lancé.<br>Vous recevrez un mail lorsqu'il sera terminé.`, color : "success", icon : "mdi-check-circle", timer : -1 })
          })
        }
    },
};
</script>
